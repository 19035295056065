<template>
  <div class="lobby-page">
    <header-nav />
    <loading :show="showLoader" />
    <v-container class="lobby-container">
      <v-row>
        <v-col cols="12" class="mt-8">
          <h1 class="lobby-description">
            Olá, <b>{{ username }}</b>! Bem vindo.
            <br>
            {{ greetMessage }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
          <v-col cols="12" md="10" xl="9">
            <v-alert type="error" prominent dismissible outlined text class="mt-4">
              Atenção: os atendimentos do canal 0800 nacional foram encerrados. O acesso aos registros de consultorias já realizadas seguirá disponível para consulta.
              <br>
              Lembre-se: <b>RegulaSUS</b> e serviços de <b>diagnóstico</b> estão disponíveis <b>apenas para os profissionais de saúde da Atenção Primária do Rio Grande do Sul</b>.
            </v-alert>
          </v-col>
        <v-spacer />
      </v-row>
      <profile-card
        :roles="roles"
        @emitter-show-loader="showLoader = true" />
    </v-container>
  </div>
</template>

<script>
  import { decodeJwt } from '@/utils/decodeJwt';
  import { getCookies } from '@/utils/cookies';

  import HeaderNav from '@/components/HeaderNav';
  import Loading from '@/components/Loading';
  import ProfileCard from '@/components/ProfileCard';

  export default {
    title: 'Lobby - TelessaúdeRS',
    name: 'LobbyPage',
    components: {
      'header-nav': HeaderNav,
      'loading': Loading,
      'profile-card': ProfileCard
    },
    data: () => ({
      roles: [],
      username: '',
      showLoader: false
    }),
    computed: {
      greetMessage() {
        return this.roles.length
          ? 'Selecione o serviço desejado'
          : 'Você não possui perfil selecionável.'
      },
    },
    created() {
      const cookies = getCookies();
      const servicetoken = decodeJwt(cookies['servicetoken']);

      this.username = this.formatUsername(servicetoken.claims.username);
      let rolesList = Object.keys(servicetoken.claims.roles).map(service => {
        // NOTE: usuário pode ter mais de um perfil igual no mesmo serviço em unidades diferentes
        return [...new Set(servicetoken.claims.roles[service])];
      }).flat();

      // NOTE: usuário pode ter mais de um perfil igual em serviços diferentes
      if (rolesList.length) this.roles = [...new Set(rolesList)];
    },
    methods: {
      formatUsername(username) {
        return username.split(' ').map((item) => {
          const title = item.toLowerCase();
          return title.charAt(0).toUpperCase() + title.slice(1);
        }).join(' ');
      },
    }
  }
</script>

<style scoped>
.lobby-page {
  min-height: 100vh;
  background-color: #F6F6F6;
}

.lobby-description {
  color: #00000099;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
}
</style>
