<template>
  <v-form v-model="formIsValid">
    <v-text-field
      id="username-input"
      v-model="username.value"
      required
      class="input-value"
      label="Email, CPF, CNS ou chave de acesso"
      :rules="rules.usernameRules"
      :error-messages="usernameErrorMessage"
      :readonly="disableLinks"
      @keyup.enter="formLoginEnterClicked()"
    />
    <v-text-field
      id="password-input"
      v-model="password.value"
      class="input-value input-group--focused"
      label="Senha"
      required
      :rules="rules.passwordRules"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPass ? 'text' : 'password'"
      :readonly="disableLinks"
      @click:append="showPass = !showPass"
      @keyup.enter="formLoginEnterClicked()"
    />
    <div>
      <alert-message
        v-if="error"
        alert-type="error"
        class-icon="alert-error-icon"
        :message="error"
      />
    </div>
    <v-card-actions class="justify-center">
      <v-btn
        block
        outlined
        :class="[
          isBtnEnable ? 'color-btn' : 'color-btn-disabled',
          'style-btn',
        ]"
        large
        :loading="isBtnLoading"
        @click.prevent="formLoginEnterClicked"
      >
        Entrar
      </v-btn>
    </v-card-actions>
    <v-card-actions class="justify-center">
      <v-btn
        block
        outlined
        color="textGreen1"
        class="style-btn"
        large
        :href="registrationUrl"
        target="_blank"
        :disabled="disableLinks"
      >
        Cadastrar
      </v-btn>
    </v-card-actions>
    <v-card-actions class="justify-center">
      <v-btn
        text
        class="forgot-password"
        color="primary"
        :disabled="disableLinks"
        @click="$emit('showForgotPassword')"
      >
        esqueci minha senha
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { debounce } from '@/utils/debounce';
import { getCookies } from '@/utils/cookies';
import { decodeJwt } from '@/utils/decodeJwt';
import { login } from '@/services/authentication';
import AlertMessage from '@/components/AlertMessage.vue';
import { isValidEmail } from '@/utils/validation';

export default {
  name: 'CardLogin',
  components: {
    'alert-message': AlertMessage
  },
  data: () => {
    return {
      disableLinks: false,
      error: '',
      formIsValid: false,
      password: { value: '', error: { message: '' }, },
      registrationUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSf3Fb67FyoOpSBX1f32EN4iGoBumOmYBGBmG1A-C9JgLugM5A/viewform',
      rules: {
        usernameRules: [
          v => !!v || 'Campo não pode estar vazio',
          v => (
            (isValidEmail(v)) ||              // email validation
            (v.length === 6 && /^[a-zA-Z]+$/.test(v)) ||  // paciente token validation
            (v.length === 11 && !isNaN(v)) || // cpf validation
            (v.length === 15 && !isNaN(v))    // cns validation
          ) || 'Informe seu e-mail, CPF, CNS ou chave de acesso para continuar',
        ],
        passwordRules: [
          v => !!v || 'Campo não pode ser vazio',
          v => v.length <= 32 || 'Campo não pode ter mais de 32 caracteres',
        ]
      },
      showPass: false,
      username: { value: '', errors: { message: '' }, },
      usernameErrorMessage: '',
      makingRequest: false,
      isBeingRedirect: false,
    }
  },
  computed: {
    isBtnEnable() {
      return this.formIsValid && !this.makingRequest && !this.isBeingRedirect;
    },
    isBtnLoading() {
      return this.disableLinks || this.isBeingRedirect;
    }
  },
  methods: {
    debounceCall: debounce(function () {
      this.handleLogin()
    }, 500),
    formLoginEnterClicked() {
      if (!this.formIsValid) return;

      this.disableLinks = true;
      this.makingRequest = true;

      this.debounceCall();
    },
    validateResponseLogin(response) {
      const defaultErrorMessage = 'Sistema temporariamente indisponível!\nSe o erro persistir contate a equipe técnica.';
      const errorMessages = {
        'A001': 'Não foi possível realizar a autenticação neste momento.\nTente novamente mais tarde.',
        'A002': 'Informações de login inválidas!\nTente novamente.',
        'A003': 'Dados enviados não são válidos!',
        'A004': 'Você excedeu o número máximo de tentativas de login.\nTente novamente daqui 5 minutos.',
        'A005': 'Usuário desativado!\nEntre em contato conosco através do telefone 08006446543 para ativar seu usuário.',
      }
      this.error = '';

      if (!response)
        this.error = defaultErrorMessage;
      else if (response.status == 'error' || response.detail)
        this.error = errorMessages[response.error_code] || defaultErrorMessage;

      if (this.error) {
        this.disableLinks = false;
        this.makingRequest = false;
        return false;
      }
      return true;
    },
    redirectLogin(jwtMod) {
      if (jwtMod == 'LEGACY') {
        this.$router.push({ name: 'LobbyPage' });
      } else if (jwtMod == 'PCT') {
        window.location.assign(process.env.VUE_APP_URL_PACIENTE);
      }
    },
    redirectUserExpiredPassword(userId) {
      const resetPasswordURL = `${process.env.VUE_APP_URL_PLATA}/accounts/dados_pessoais/usuario/${userId}/senha/`;
      window.location.assign(resetPasswordURL);
    },
    async handleLogin() {
      const response = await login(this.username.value, this.password.value);

      if (!this.validateResponseLogin(response.data)) return;

      this.makingRequest = false;

      const cookies = getCookies();
      const responseDecoded = decodeJwt(cookies.servicetoken);

      this.isBeingRedirect = true;

      if (responseDecoded['claims']['expired']) {
        this.redirectUserExpiredPassword(responseDecoded['claims']['id']);
        return;
      }

      if (this.$route.query.nextUrl) {
        window.location.assign(this.$route.query.nextUrl);
        return;
      }

      this.redirectLogin(responseDecoded['mod']);
      this.isBeingRedirect = false;
    },
  },
};
</script>

<style scoped>
.input-value {
  text-align: left;
  font: normal normal normal 21px/32px Lato;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.54;
  font-size: 16px;
}

.forgot-password {
  text-align: left;
  text-transform: none;
  margin-bottom: 65px;
  font: normal normal 600 14px/35px Lato;
  letter-spacing: 0.12px;
  opacity: 1;
}

.forms .v-label  {
  color:#19B1AB;
  text-align: left;
  font: normal normal 600 14px/35px Lato;
  letter-spacing: 0.12px;
  opacity: 1;
}

.style-btn {
  text-align: center;
  font: normal normal bold 16px/19px Lato;
  color: #FFFFFF;
}

.color-btn {
  background-color: #19b2ac;
}

.color-btn-disabled {
  background-color: #127F7C;
  opacity: 0.6;
  pointer-events: none;
}

.v-card__actions {
  padding: 0;
  padding-bottom: 10px;
}

.v-btn:not(.v-btn--round).v-size--default  {
  padding: 0 4px;
}
</style>
