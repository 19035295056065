<template>
  <v-row class="justify-center">
    <v-spacer />
    <v-col cols="10" class="d-flex flex-wrap justify-center px-0">
      <div v-for="(service, index) in getServices" :key="index">
        <div v-if="service.visible" class="card-lobby">
          <div class="card-lobby-info">
            <v-tooltip top max-width="235px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="!!service.description" v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ service.description }}</span>
            </v-tooltip>
          </div>
          <a
            :href="service.url"
            :title="service.title"
            class="card-lobby-link"
            @click="prevent($event, service.url)">
            <v-icon size="64" color="textGreen1">
              {{ service.icon }}
            </v-icon>
            <div class="card-lobby-link-body">
              <h2 class="card-lobby-link-body-title">
                {{ service.title }}
              </h2>
            </div>
          </a>
        </div>
      </div>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import { debounce } from '@/utils/debounce';

export default {
  name: "ProfileCard",
  props: { roles: { type: Array, required: true } },
  computed: {
    hasPermissionSte() {
      const rolesAccepted = ["solicitante"];
      return this.roles.some(role => rolesAccepted.includes(role));
    },
    hasPermission0800() {
      const rolesAccepted = [
        "administrador",
        "coordenador",
        "monitor",
        "teleconsultor",
        "telerregulador",
        "regulador externo",
      ];
      return this.roles.some(role => rolesAccepted.includes(role));
    },
    hasPermissionTDX() {
      const rolesAccepted = [
        "administrador",
        "coordenador",
        "monitor",
        "tecnico",
        "teleconsultor",
        "telerregulador",
        "solicitante",
      ];
      return this.roles.some(role => rolesAccepted.includes(role));
    },
    hasPermissionDynamicPrescription() {
      const rolesAccepted = [
        "administrador",
        "coordenador",
        "teleconsultor",
        "telerregulador",
      ];
      return this.roles.some(role => rolesAccepted.includes(role));
    },
    hasPermissionTeleNutri() {
      const rolesAccepted = [
        "consultaremota_administrador",
        "consultaremota_monitor",
        "telenutri_consultor",
      ];
      return this.roles.some(role => rolesAccepted.includes(role));
    },
    getServices() {
      const url0800 = process.env.VUE_APP_URL_PLATA + "/teleconsultoria/entrada/";
      const urlTDX = process.env.VUE_APP_URL_PLATA + "/servicos/nucleo/todos/caixa_entrada/";
      const urlRemoteCare = process.env.VUE_APP_URL_TELESSAUDE + "/consultas-remotas/telenutri";
      let urlDynamicPrescription = process.env.VUE_APP_URL_TELESSAUDE + "/consultorias/consultoria-ativa";

      const descriptionSolicitante = `Criar e editar protocolos de anexos, além de visualizar as consultorias 
        realizadas por meio do canal 0800 644 6543.`;
      const descriptionTDX = `Solicitação de espirometrias (RespiraNet), diagnóstico em dermatologia (DermatoNet) e 
        estomatologia (EstomatoNet), e consultoria assíncrona para feridas complexas (TeleFeridas).`;

      if (this.roles.includes("coordenador") && !this.roles.includes("teleconsultor"))
        urlDynamicPrescription = process.env.VUE_APP_URL_TELESSAUDE + "/cadastros/hipoteses";

      const titleTdx = this.roles.includes("solicitante")
        ? "Solicitante Diagnóstico e TeleFeridas"
        : "Diagnóstico e TeleFeridas";

      return [
        {
          title: "Solicitante (0800)",
          url: process.env.VUE_APP_URL_SOLICITANTE,
          icon: "mdi-account",
          visible: this.hasPermissionSte,
          description: descriptionSolicitante,
        },
        {
          title: "Regulação e Consultoria",
          url: url0800,
          icon: "mdi-phone",
          visible: this.hasPermission0800,
          description: null,
        },
        {
          title: titleTdx,
          url: urlTDX,
          icon: "mdi-medical-bag",
          visible: this.hasPermissionTDX,
          description: descriptionTDX,
        },
        {
          title: "Consultoria Ativa",
          url: urlDynamicPrescription,
          icon: "mdi-laptop",
          visible: this.hasPermissionDynamicPrescription,
          description: null,
        },
        {
          title: "Tele Nutri",
          url: urlRemoteCare,
          icon: "mdi-food-apple",
          visible: this.hasPermissionTeleNutri,
          description: null,
        },
      ]
    },
  },
  methods: {
    prevent(event, url) {
      event.preventDefault();
      this.$emit('emitter-show-loader');
      this.redirect(url);
    },
    redirect: debounce(function(url) {
      window.location.assign(url);
    }, 500),
  }
};
</script>

<style scoped>
.card-lobby {
  height: 182px;
  width: 171px;
  margin: 8px;

  background-color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  transition: border .2s ease;
}
.card-lobby:hover,
.card-lobby:focus {
  border: 2px solid #008C88;
  cursor: pointer;
}

.card-lobby-info {
  width: 100%;
  min-height: 32px;
  padding: 8px 8px 0 0;
  display: flex;
  flex-flow: row-reverse;
}

.card-lobby-link {
  padding: 0px 18px;

  outline: none;
  text-decoration: none;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-lobby-link-body {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.card-lobby-link-body-title {
  color: #19b2ac;
  font-family: "Lato", sans-serif;
  font-size: 19px;
  font-weight: normal;
  line-height: 22px;
}
</style>
